exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../../../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-companies-en-js": () => import("./../../../src/pages/companies.en.js" /* webpackChunkName: "component---src-pages-companies-en-js" */),
  "component---src-pages-cookie-policy-en-js": () => import("./../../../src/pages/cookie-policy.en.js" /* webpackChunkName: "component---src-pages-cookie-policy-en-js" */),
  "component---src-pages-digital-birthday-invitations-en-js": () => import("./../../../src/pages/digital-birthday-invitations.en.js" /* webpackChunkName: "component---src-pages-digital-birthday-invitations-en-js" */),
  "component---src-pages-digital-communion-invitations-en-js": () => import("./../../../src/pages/digital-communion-invitations.en.js" /* webpackChunkName: "component---src-pages-digital-communion-invitations-en-js" */),
  "component---src-pages-digital-wedding-invitations-en-js": () => import("./../../../src/pages/digital-wedding-invitations.en.js" /* webpackChunkName: "component---src-pages-digital-wedding-invitations-en-js" */),
  "component---src-pages-empresas-js": () => import("./../../../src/pages/empresas.js" /* webpackChunkName: "component---src-pages-empresas-js" */),
  "component---src-pages-free-invitations-en-js": () => import("./../../../src/pages/free-invitations.en.js" /* webpackChunkName: "component---src-pages-free-invitations-en-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invitaciones-digitales-boda-js": () => import("./../../../src/pages/invitaciones-digitales-boda.js" /* webpackChunkName: "component---src-pages-invitaciones-digitales-boda-js" */),
  "component---src-pages-invitaciones-digitales-comunion-js": () => import("./../../../src/pages/invitaciones-digitales-comunion.js" /* webpackChunkName: "component---src-pages-invitaciones-digitales-comunion-js" */),
  "component---src-pages-invitaciones-digitales-cumpleanos-js": () => import("./../../../src/pages/invitaciones-digitales-cumpleanos.js" /* webpackChunkName: "component---src-pages-invitaciones-digitales-cumpleanos-js" */),
  "component---src-pages-invitaciones-gratis-js": () => import("./../../../src/pages/invitaciones-gratis.js" /* webpackChunkName: "component---src-pages-invitaciones-gratis-js" */),
  "component---src-pages-legal-notice-en-js": () => import("./../../../src/pages/legal-notice.en.js" /* webpackChunkName: "component---src-pages-legal-notice-en-js" */),
  "component---src-pages-politica-de-cookies-js": () => import("./../../../src/pages/politica-de-cookies.js" /* webpackChunkName: "component---src-pages-politica-de-cookies-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../../../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */),
  "component---src-pages-pricing-en-js": () => import("./../../../src/pages/pricing.en.js" /* webpackChunkName: "component---src-pages-pricing-en-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */)
}

